<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">我要入园</el-breadcrumb-item>
      <el-breadcrumb-item>材料管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content-box">
      <div class="title bb fl-c-b">材料管理
          <el-button type="primary" @click="add">新建</el-button>
      </div>
      <div class="fl-box">
        <div class="folder">
          <!-- <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
              <template slot="title">
                <img src="../../../assets/img/brainDecision/icon_wjj.png" alt="" class="icon_wjj" />
                全部文件夹
              </template> -->
              <div class="folder-box">
                <el-input placeholder="请输入文件名" v-model="all" class="search-input">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <el-tree class="filter-tree" :data="data" :props="defaultProps" :filter-node-method="filterNode" ref="allTree" @node-click="getData"> </el-tree>
              </div>
            <!-- </el-collapse-item>
          </el-collapse> -->
        </div>
        <div class="table-box">
          <!-- <el-button size="medium" class="sortBtn" @click="changeSort" icon="el-icon-sort">按名称</el-button> -->
          <el-table :data="queryTable.table" v-loading="queryTable.loading">
            <!-- <el-table-column type="selection" width="45"></el-table-column> -->
            <el-table-column label="序号" align="center" width="50" type="index"></el-table-column>
            <el-table-column label="材料名称" prop="fileName" align="center"></el-table-column>
            <el-table-column label="材料类型" prop="typeName" align="center"></el-table-column>
            <!-- <el-table-column label="管理部门" prop="dept" align="center"></el-table-column> -->
            <el-table-column label="上传时间" prop="createTime" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link type="primary" :underline="false" style="color: #135694" :href="$store.state.fileUrl + scope.row.attachmentUrl" target="_blank">下载</el-link>
                <el-link type="primary" :underline="false" style="color: #135694; margin: 0 10px" @click="del(scope.row.id)">删除</el-link>
                <!-- <el-button @click="look(scope.row)" style="color: #135694" type="text">预览</el-button> -->
                <!-- <el-button @click="look(scope.row)" style="color: #135694" type="text">下载</el-button>
                <el-button @click="look(scope.row)" style="color: #135694" type="text">收藏</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top: 25px">
            <el-pagination
              background
              layout="total, prev, pager, next, jumper"
              :page-size="queryTable.pageSize"
              :total="queryTable.count"
              :current-page.sync="queryTable.page"
              @current-change="queryTable.search()"
            ></el-pagination>
          </el-row>
        </div>
        <el-dialog :close-on-click-modal="false" title="材料管理" :visible.sync="saveDialogVisable" :append-to-body="true">
          <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="107px" label-position="left">
            <el-row :gutter="28">
              <el-col :span="12">
                <el-form-item label="材料类型" prop="type">
                  <el-select v-model="saveData.type" placeholder="请选择材料类型">
                    <el-option :label="item.tTypeName" :value="item.id" v-for="(item, index) in data" :key="index"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="28">
              <div class="file">
                上传附件:
                <file-upload
                  :file-type="[]"
                  :files="saveData.file"
                  :limit="99"  multiple
                  :size-limit="20"
                  size-unit="M"
                  :isImg="false"
                  tip="文件大小不能超过20M"
                  style="margin-left: 10px"
                ></file-upload>
              </div>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="saveDialogVisable = false">取消</el-button>
            <el-button type="primary" @click="save()" >保存</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { QueryTable } from '@/assets/js/QueryTable';
import fileUpload from '@/components/FileUpload';
// import config from '@/http/config.js';
export default {
  data() {
    return {
      id: '',
      table: [],
      queryTable: new QueryTable(
        {},
        {
          projectCode: '',
          projectName: '',
          type: '',
          parentId: 2,
        },
        this.$api.getEnterpriseMaterialList
      ),
      activeNames: ['1'],
      all: '',
      collection: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'tTypeName',
      },
      saveDialogVisable: false,
      saveData: {},
      saveInitData: {
        type: '',
        file: [],
      },
      saveRules: {
        type: [{ required: true, message: '请选择材料类型', trigger: 'blur' }],
      },
      // selectedData: [],
    };
  },
  methods: {
    add() {
      this.saveDialogVisable = true;
      this.$nextTick(() => {
        this.$refs['saveForm'].resetFields();
        this.saveData = JSON.parse(JSON.stringify(this.saveInitData));
      });
    },
    save() {
      this.$refs['saveForm'].validate((valid) => {
        if (valid) {
          if (this.saveData.length < 1) {
            this.$message.error({ message: `请上传 附件`, offset: 80 });
            return false;
          }
          this.saveData.file[0].type = this.saveData.type;
          let p = {
            projectId: this.id,
            attachmentEntityList: JSON.stringify(this.saveData.file)
          };
          this.$api.saveEnterpriseMaterialList(p).then((res) => {
            if (res.success) {
              this.$message.success({ message: '新增成功', offset: 80 });
              this.saveDialogVisable = false;
              this.queryTable.search();
            } else {
              this.$message.error({ message: res.message, offset: 80 });
            }
          });
        } else {
          return false;
        }
      });
    },
    del(id){
      this.$confirm(`确定删除此数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$api.deleteEnterpriseMaterial({id}).then((res) => {
          if (res.success) {
            this.$message.success({ message: '删除成功', offset: 80 });
            this.queryTable.search();
          } else {
            this.$message.error({ message: res.message, offset: 80 });
          }
        });
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    look() {},
    getData(e) {
      this.queryTable.condition.parentId = e.id;
      this.queryTable.query();
    },
  },
  watch: {
    all(val) {
      this.$refs.allTree.filter(val);
    },
    collection(val) {
      this.$refs.collectionTree.filter(val);
    },
  },
  components: {
    fileUpload,
  },
  mounted() {},
  created() {
    this.$api.getSafeProjectId().then((res) => {
      this.id = res.result.generalProjectId || res.result.safeProjectId;
    });
    this.queryTable.search();
    // this.$api.getEnterpriseTemplateMaterialTreeList().then((res) => {
    // });
  
    this.$api.getEnterpriseMaterialUploadTreeList().then((res) => {
      this.data = res.result;
    });
  },
};
</script>


<style lang='scss' scoped>
.el-tree {
  background: transparent;
  color: #333333;
  /deep/ .el-tree-node__expand-icon {
    color: #333333;
    &.is-leaf {
      color: transparent;
    }
  }
}
// /deep/ .el-collapse-item__header {
//   background: #cee4ff;
//   height: 40px;
//   line-height: 40px;
//   font-size: 14px;
//   color: #333333;
//   border: none;
//   padding-left: 16px;
//   padding-right: 7px;
//   .icon_wjj {
//     margin-right: 8px;
//     width: 14px;
//     height: 14px;
//   }
//   .el-collapse-item__arrow {
//     transform: rotate(-90deg);
//     color: #0e459c;
//   }
//   .el-collapse-item__arrow.is-active {
//     transform: rotate(90deg);
//   }
// }
// /deep/ .el-collapse-item__wrap {
//   background: #ecf5ff;
// }
.content-box {
  background-color: #fff;
  padding: 0 20px 51px;
}

.title {
  line-height: 74px;
  font-size: 26px;
  color: #333333;
  font-weight: 600;
  padding-left: 14px;
  position: relative;
  margin-bottom: 16px;
  &::after {
    content: '';
    position: absolute;
    top: 28px;
    left: 0;
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
  }
}
.time {
  font-size: 14px;
  color: #666666;
}
.search-box {
  margin-bottom: 16px;
  border: 1px solid #ddd;
}
.folder {
  background: #ecf5ff;
  width: 200px;
  margin-right: 20px;
  border-radius: 4px;
  // overflow: hidden;
  .folder-box {
    padding: 16px;
    .search-input {
      margin-bottom: 16px;
      /deep/ .el-input__inner {
        border: 1px solid #b2c1d9;
        border-radius: 15px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
        color: #cccccc;
      }
    }
  }
}
.table-box {
  width: calc(100% - 220px);
  .sortBtn {
    height: 32px;
    line-height: 20px;
    font-size: 14px;
    padding: 6px 14px;
    margin-bottom: 16px;
  }
}
.file {
  display: flex;
  color: #000000;
  line-height: 40px;
  margin-left: 50px;
}
</style>